.link-block{
    width: 82%;
    background-color: #D42323;
    padding: 4%;
    background-size: 90%;
    background-position: center top;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    margin-top: 2%;
}
.link-block-desc{
    font-size: 80%;
    color: white;
}
.link-block-title{
    font-size: 110%;
    color: white;
    margin-bottom: 2%;
}
.link-block-btn {
    margin-top: 2%;
    width: 48%;
    border: none;
    padding: 3% 5%;
    border-radius: 8px;
    font-weight: 600;
    font-size: 70%;
    background-color: white;
}
.link-block-sourse{
    margin-top: 15%;
    width: 92%;
    padding: 2% 3%;
    background-color: white;
    border-radius: 5px;
    font-size: 80%;
}