.question-wrap{
    width: 82%;
    background-image: url('../../public/img/Banner.jpeg');
    padding: 4%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    margin-bottom: 4%;
}

.question-title{
    font-size: 100%;
    color: white;
}
.question-main-title{
    font-size: 150%;
    font-weight: 500;
    margin-bottom: 1%;
    width: 90%;
}

.question-guid{
    margin-top: 5%;
    font-size: 80%;
    color: white;
}

.answer{
    padding: 3% 3%;
    text-align: left;
    width: 85%;
    border-radius: 7px;
    background: #EFF0F2;
    margin-top: 2%;
}

.answer-act{
    padding: 3% 3%;
    text-align: left;
    width: 85%;
    border-radius: 7px;
    background: #afafaf;
    margin-top: 2%;
}
.next-question-btn{
    position: absolute;
    padding: 2% 3%;
    background: #D42323;
    width: 85%;
    color: white;
    border-radius: 6px;
    bottom: 7%;
    text-align: center;
    font-size: 100%;
}
.prev-question-btn{
    position: absolute;
    padding: 2% 3%;
    background: #000000;
    width: 85%;
    color: white;
    border-radius: 6px;
    bottom: 13%;
    text-align: center;
    font-size: 100%;
}