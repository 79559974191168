/* Preloader.css */
.preloader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Занимает всю высоту экрана */
    background-color: rgba(255, 255, 255, 0.8); /* Полупрозрачный фон */
  }
  
  .spinner {
    border: 8px solid rgba(255, 255, 255, 0.3); /* Белый цвет с прозрачностью */
    border-top: 8px solid red; /* Красный цвет для верхней части */
    border-radius: 50%;
    width: 50px; /* Размер спиннера */
    height: 50px; /* Размер спиннера */
    animation: spin 1s linear infinite; /* Анимация вращения */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  