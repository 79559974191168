.item-3-wrap{
    width: 90%;
    padding: 2%;
    border-radius: 10px;
    display: flex;
    box-shadow: 0px 2px 3px 2px rgba(0, 0, 0, 0.1);
    align-items: center;
    margin-bottom: 2%;
    margin-top: 2%;
    position: relative;
    background-color: white;
}
.item-3-title{
    font-size: 80%;
}
.item-3-desc{
    font-size: 50%;
}
.item-3-text-wrap{
    display: flex;
    flex-direction: column;
}
.item-3-img{
    aspect-ratio: 1;
    width: 8%;
    /* background: grey; */
    border-radius: 5px;
    margin-right: 2%;
}

.item-3-btn-abl{
    padding: 1% 2%;
    font-size: 80%;
    background-color: #D42323;
    color: white;
    position: absolute;
    right: 2%;
    border-radius: 100px;
}

.item-3-btn-disabl{
    padding: 1% 2%;
    font-size: 80%;
    background-color: #EFF0F2;
    color: rgb(0, 0, 0);
    position: absolute;
    right: 2%;
    border-radius: 100px;
}