@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');


body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Manrope", sans-serif;
  font-optical-sizing: auto;
  overflow-y: scroll;
  background-color: white;
}
.App{
  display: flex;
  flex-direction: column;
  align-items: center; /* Центрирование по вертикали */
  height: 100vh; /* Задаем высоту родителя */
  width: 100vw;
  padding-bottom: 70px;
}
.title-type-2{
  font-size: 150%;
  margin-top: 2%;
  width: 90%;
  font-weight: 500;
}
