.friend-wrap{
    width: 85%;
    padding: 2%;
    border-radius: 10px;
    display: flex;
    box-shadow: 0px 2px 3px 2px rgba(0, 0, 0, 0.1);
    align-items: center;
    margin-bottom: 2%;
    margin-top: 2%;
    position: relative;
    font-weight: 500;
}
.friend-title{
    font-size: 80%;
}
.friend-desc{
    font-size: 50%;
}
.friend-text-wrap-left{
    display: flex;
    flex-direction: column;
    width: 45%;
    .friend-title{
        font-size: 90%;
    }
    .friend-desc{
        font-size: 60%;
    }
}
.friend-text-wrap-right{
    display: flex;
    flex-direction: column;
    justify-content: right;
    width: 45%;
    text-align: right;
    .friend-title{
        font-size: 80%;
        
    }
    .friend-desc{
        font-size: 60%;
        margin-right: 1%;
    }
}
.friend-img{
    aspect-ratio: 1;
    width: 12%;
    background: grey;
    border-radius: 100px;
    margin-right: 2%;
}

.item-3-btn-abl{
    padding: 1% 2%;
    font-size: 80%;
    background-color: #D42323;
    color: white;
    position: absolute;
    right: 2%;
    border-radius: 100px;
}