.contact-form-wrap{
    width: 95%;
    display: flex;
    align-items: left;
    flex-direction: column;
    input{
        border: none;
        padding: 4%;
        border-radius: 12px;
        margin-top: 3%;
        box-shadow: 0px 2px 3px 2px rgba(0, 0, 0, 0.1);
    }
    button{
    border: none;
    position: absolute;
    padding: 2% 3%;
    background: #D42323;
    width: 95%;

    color: white;
    border-radius: 6px;
    bottom: 12%;
    text-align: center;
    font-size: 100%;
    }
}