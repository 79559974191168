.post-item-wrap{
    padding: 5%;
    width: 55%;
    border-radius: 25px;
}
.post-item-title{

}
.post-item-desc{

}