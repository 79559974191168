.balance{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.user-pa-balance-wrap{
    width: 86%;
    flex-direction: column;
    display: flex;
    padding: 3%;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-position: center right;
    background-size: contain;
    margin-top: 2%;
    box-shadow: 0px 2px 3px 2px rgba(0, 0, 0, 0.1);
}
.your-balance{
    font-weight: 500;
    font-size: 90%;
}
.user-pa-balance-value{
    font-size: 200%;
    font-weight: 600;
}
.user-pa-balance-tasks-btn{
    background-color: #D42323;
    padding: 1% 3%;
    border-radius: 100px;
    color: white;
    width: fit-content;
    margin-top: 5%;
}
.user-pa-balance-lotery-btn{
    background-color: #D42323;
    padding: 1% 3%;
    border-radius: 100px;
    color: white;
    width: fit-content;
    margin-top: 0;
    margin-bottom: 15%;
}
.user-pa-item-wrap{
    width: 86%;
    padding: 3% 2%;
    display: flex;
    flex-direction: column;
    margin-top: 2%;
    border-radius: 10px;
    box-shadow: 0px 2px 3px 2px rgba(0, 0, 0, 0.1);
}
.user-pa-item-title{
    font-size: 105%;
    font-weight: 500;
}
.user-pa-item-desc{
    font-size: 95%;
    font-weight: 300;
}

.arrow {
    transition: transform 0.3s ease; /* Анимация для стрелочки */
}

.arrow.up {
    transform: rotate(180deg); /* Поворачиваем стрелочку вверх */
}

/* / */
.get-check{
    padding: 2% 3%;
    background: #D42323;
    width: 85%;
    color: white;
    border-radius: 6px;
    bottom: 12%;
    text-align: center;
    font-size: 100%;
    margin-top: 2%;
}

.locked{
    padding: 2% 3%;
    background: #ffffff;
    width: 85%;
    color: rgb(0, 0, 0);
    border-radius: 6px;
    bottom: 12%;
    text-align: center;
    font-size: 100%;
    margin-top: 2%;
    font-weight: 500;
    margin-bottom: 50%;
    box-shadow: 0px 2px 3px 2px rgba(0, 0, 0, 0.1);
}

.lottery-cupone-wrap{
    background-size: 65%;
    background-position-x: 150%;
    background-position-y: 50%;
    background-repeat: no-repeat;
    width: 86%;
    padding: 2%;
    display: flex;
    flex-direction: column;
    margin-top: 2%;
    border-radius: 10px;
    box-shadow: 0px 2px 3px 2px rgba(0, 0, 0, 0.1);
}


.succes-wrap{
    padding: 2% 3%;
    background: #D42323;
    width: 85%;
    color: white;
    border-radius: 10px;
    bottom: 12%;
    text-align: center;
    font-size: 100%;
    margin-top: 2%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    text-align: left;
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: bottom;
    background-size: 50%;
}
.succes-title{
    font-size: 160%;
    font-weight: 500;
}
.succes-increment{
    position: absolute;
    right: 10%;
}
.succes-desc{
    margin-top: 7%;
    font-size: 80%;
    font-weight: 300;
}
.lottery-cupone-wrap-type-1{
    background-size: 65%;
    background-position-x: 150%;
    background-position-y: 50%;
    background-repeat: no-repeat;
    width: 86%;
    padding: 2%;
    display: flex;
    flex-direction: row;
    margin-top: 2%;
    border-radius: 10px;
    box-shadow: 0px 2px 3px 2px rgba(0, 0, 0, 0.1);
    justify-content: space-between;
}
.lottery-cupone-title{
    font-size: 80%;
    font-weight: 500;
}

.lottery-cupone-desc{
    font-size: 80%;
    font-weight: 300;
    margin-top: 2%;
}