.header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    padding: 2%;
    margin: 3%;
}
.header-logo{
    width: 35%;
}
.gift-block{
    padding: 2%;
    background-color: black;
    color: white;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    span{
        padding-left: 5%;
        font-size: 70%;
    }
    img{
        width: 19%;
    }
}
.title-type-1 {
    width: 90%;
    padding: 2%;
    font-size: 110%;
    font-weight: 500;
}