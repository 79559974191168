.quiz-block{
    width: 82%;
    background-image: url('../../../public/img/vibrant-red-truck-with-container-against-industrial-background 1.svg');
    padding: 4%;
    background-size: 90%;
    background-position: center top;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    span{
        font-size: 80%;
        color: white;
    } 
    margin-bottom: 2%;
}
.quiz-block-btn {
    margin-top: 2%;
    width: fit-content;
    border: none;
    padding: 2%;
    border-radius: 100px;
    font-size: 70%;
}
