.nav-pan {
    width: 90%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    font-size: 50%;
    align-items: center;
    justify-content: space-between;
    background-color: #FAFAFA;
    position: fixed;
    bottom: 2%;
    left: 5%;
    z-index: 1000;
    border-radius: 5px;
}

.nav-pan-act-item {
    background-color: #D42323;
    display: flex;
    flex-direction: column;
    color: white;
    padding: 5% 3%;
    align-items: center;
    border-radius: 10px;
}

.nav-pan-def-item {
    display: flex;
    flex-direction: column;
    padding: 5% 3%;
    align-items: center;
    border-radius: 10px;
}

.nav-pan img {
    width: 30%;
}